<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        تکمیل سفارش تبلیغ هدفمند
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          ثبت سفارش تبلیغات به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایت و نحوه تبلیغات
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>بعد از تکمیل سفارش و پرداخت میتوانید بنر و کلید ها را مشخص کنید</li>
      </ul>
    </template>
    <template #default>
      <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5 pb-1 mb-5">
        <h2 class="text-md font-bold">جستجو در گروه ها</h2>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-1 md:gap-4 w-full mt-4">
          <MnrText
            placeholder="جستجو"
            hint="میتوانید نام گروه، نام کشور، شهر و ... را جستجو کنید"
            class="col-span-2"
            v-model="search"
            @input="onInput"
          ></MnrText>
          <MnrSelect
            v-model="gender.value"
            :options="gender.options"
            placeholder="آقا و خانم"
            hint="جنسیت گروه"
            @input="onInput"
          ></MnrSelect>
        </div>
        <p class="mt-2" v-if="searching">در حال جستجو ...</p>
        <p class="mt-2" v-else>
          <span class="font-bold text-lg">{{ groups.length }}</span>
          گروه از {{ groupsCount }} گروه
        </p>
      </div>
      <div v-show="!searching">
        <div
          v-for="group in groups"
          :key="group.id"
          class="
            bg-blueGray-100
            border border-blueGray-200
            rounded-md
            my-5
            flex flex-wrap
            overflow-hidden
          "
        >
          <div
            class="
              w-full
              sm:w-5/12
              md:w-4/12
              lg:w-3/12
              border-b
              sm:border-l sm:border-b-0
              border-blueGray-200
              p-5
            "
          >
            <h2 class="text-md font-bold text-center">{{ group.name }}</h2>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ $number.format(group.members) }}</div>
              <div class="text-left">ممبر</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ $number.format(group.ad_price) }}</div>
              <div class="text-left">تومان</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ list_gender[group.gender] ?? "هردو" }}</div>
              <div class="text-left">جنسیت</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.country || "-" }}</div>
              <div class="text-left">کشور</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.province || "-" }}</div>
              <div class="text-left">استان</div>
            </div>
            <div class="mt-2 grid grid-cols-2 gap-1">
              <div class="font-bold">{{ group.city || "-" }}</div>
              <div class="text-left">شهر</div>
            </div>
            <Button
              :to="{
                name: 'TargetOrderInGroup',
                params: {
                  id: $store.state.user.id,
                  adId: $route.params.adId,
                  groupId: group.id,
                },
              }"
              class="mt-3 text-sm h-8 lg:h-10 hidden sm:flex"
              color="cyan"
            >
              ساعت های بیشتر
            </Button>
          </div>
          <div
            class="
              w-full
              sm:w-7/12
              md:w-8/12
              lg:w-9/12
              grid grid-cols-2
              sm:grid-cols-3
              lg:grid-cols-4
              2xl:grid-cols-6
              auto-rows-max
              gap-2
              p-3
            "
          >
            <TargetTime
              v-for="time in group.times"
              :key="group.id + '_' + time.en"
              :time="time"
              :send="L_.find(this.sends?.[group.id], ['send_at.def', time.en]) ?? {}"
              :groupId="group.id"
              @changeTime="setSends($event)"
            />
            <Button
              :to="{
                name: 'TargetOrderInGroup',
                params: {
                  id: $store.state.user.id,
                  adId: $route.params.adId,
                  groupId: group.id,
                },
              }"
              class="mt-3 text-sm col-span-full h-8 lg:h-10 sm:hidden"
              color="cyan"
            >
              ساعت های بیشتر
            </Button>
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-1 md:gap-4 w-full">
        <div class="text-center flex justify-center items-center">
          {{ Object.values(this.sends).length }} گروه
        </div>
        <div class="text-center flex justify-center items-center">
          {{ L_.flatten(Object.values(this.sends)).length }} ارسال
        </div>
        <div class="text-center flex justify-center items-center">
          {{ $number.format(L_.sumBy(L_.flatten(Object.values(this.sends)), "price")) }}
          تومان
        </div>
        <div class="text-center flex justify-center items-center">
          <Button
            :to="{
              name: 'TargetOrderSelected',
              params: { id: $store.state.user.id, adId: $route.params.adId },
            }"
            class="text-sm h-10 lg:h-12"
            color="green"
          >
            تکمیل سفارش
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";
import TargetTime from "@/components/TargetTime.vue";

export default {
  components: {
    Icon,
    MnrSelect,
    MnrText,
    Button,
    BodySimple,
    TargetTime,
  },
  data() {
    return {
      firstLoading: true,
      errors: [],
      gender: {
        value: 0,
        options: [
          {
            text: "آقا و خانم",
            value: 0,
          },
          {
            text: "فقط آقا",
            value: "male",
          },
          {
            text: "فقط خانم",
            value: "female",
          },
        ],
      },
      list_gender: {
        male: "آقا",
        female: "خانم",
      },
      search: "",
      showPro: false,
      searching: false,
      groups: [],
      sends: [],
      groupsCount: 0,
      debounced: _.debounce(this.getGroups, 1500),
      L_: _,
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.search = $this.$route.query.search ?? "";
        $this.gender.value = $this.$route.query.gender ?? 0;

        $this.getGroups();
        $this.getAd();
      })
      .catch();
  },
  methods: {
    onInput() {
      this.$router.replace({ query: { search: this.search, gender: this.gender.value } });
      this.searching = true;
      this.debounced.cancel;
      this.debounced();
    },
    getGroups() {
      let $this = this;
      $this.$axios
        .get("api/ad-targets-select/" + $this.$route.params.adId + "/groups", {
          params: {
            id: $this.$store.state.user.id,
            search: $this.search,
            gender: $this.gender.value,
          },
        })
        .then(function (response) {
          $this.groups = response.data.groups;
          $this.groupsCount = response.data.all;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.searching = false;
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
    getAd() {
      let $this = this;
      $this.$axios
        .get("api/ad-targets-select/" + $this.$route.params.adId + "/ad", {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.setSends(response.data.ad);
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
    setSends(ad) {
      this.sends = _.groupBy(ad.sends, "group_id");
    },
  },
};
</script>
